import React,{useState} from "react";
import Yida from "../../YidaRender/yidaRender";

const OriginalYida = (props) => {
    const {moduleItem,dataInfo} =props
    const [yidaModel] = useState({
        pageId: moduleItem.pageId,
        info:dataInfo
      });
    const callback = ()=>{      
      props.callback()
    }
  return (
    <>
      <Yida model={yidaModel} callback={callback} ></Yida>
    </>
  );
};

export default OriginalYida;
