import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Form, Tooltip, Row, Alert, Select, Tabs, Dropdown, Spin, Menu, Empty, Checkbox, Modal } from 'antd';
import { EyeOutlined, PicLeftOutlined, DownloadOutlined, StarOutlined, PushpinOutlined, SearchOutlined, EllipsisOutlined, DownOutlined, CloudServerOutlined, ContainerOutlined } from "@ant-design/icons";
import XFormItem from "@/components/FormItem";
import XIconFont from "@/components/IconFont";
import "./index.less";
import RViewerJS from 'viewerjs-react';
import commonService from "@/service/common";
import moment from "moment";

import { Base64 } from 'js-base64';
const { Option } = Select;
const SmartGallery = (props) => {
    const [form] = Form.useForm();
    const [galleryData, setGalleryData] = useState(null);
    const [pageIndex, setPageIndex] = useState(1);
    const [totalCount, setTotalCount] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loding, setLoding] = useState(false);
    const [parameters, setParameters] = useState(null);
    const [Fileds, setFileds] = useState(null);
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('');
    const actionsMenu = (data) => {
        return <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <a>
                            提取文字
                        </a>
                    ),
                    icon: <ContainerOutlined />,
                    disabled: true,
                },
                {
                    key: '5',
                    label: (
                        <a>
                            翻拍识别
                        </a>
                    ),
                    icon: <CloudServerOutlined />,
                    disabled: true,
                },
                {
                    key: '2',
                    label: (
                        <a onClick={() => { downLoadFile(data) }}>
                            下载
                        </a>
                    ),
                    icon: <DownloadOutlined />,
                },
                {
                    key: '3',
                    label: (
                        <a>
                            收藏
                        </a>
                    ),
                    icon: <StarOutlined />,
                    disabled: true,
                },

                {
                    key: '4',
                    label: (
                        <a>
                            打标
                        </a>
                    ),
                    icon: <PushpinOutlined />,
                    disabled: true,
                }, {
                    key: '4',
                    label: (
                        <a>
                            会议详情
                        </a>
                    ),
                    icon: <PicLeftOutlined />,
                    disabled: true,
                },
            ]}
        />
    }
    const downLoadFile = (data) => {
        const link = document.createElement("a");
        link.style.display = "none";
        link.target = "_blank";
        link.href = data.url;
        link.click();
    }
    useEffect(() => {
        GetQueryObjectX();
    }, []);
    useEffect(() => {
        const ele = document.getElementById('myIframe')
        if (ele && isModalOpen) {
            const iframeEle = ele instanceof HTMLIFrameElement ? ele : null;
            if (iframeEle.contentWindow && iframeEle.contentWindow.location) {
                iframeEle.contentWindow.location.href = iframeUrl;
            }
            if (ele.contentDocument && ele.contentDocument.readyState === 'complete') {
                console.log('iframe加载完成')
                setLoding(false)
            }
        }
    }, [isModalOpen]);
    useEffect(() => {
        parameters && onSearch();
    }, [parameters]);
    const scrollData = async (dom) => {
        const { scrollHeight, clientHeight, scrollTop } = dom.target
        // const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        console.log(scrollTop, scrollHeight, clientHeight, "0--------")
        // && galleryData.length < totalCount
        if (scrollTop + clientHeight >= scrollHeight && !loding) { // 5px buffer
            let pageIndexP = pageIndex
            setPageIndex(pageIndexP + 1)
            getQueryData(pageIndexP + 1, Fileds);
        }
    }
    const lodeMore = () => {
        let pageIndexP = pageIndex
        setPageIndex(pageIndexP + 1)
        getQueryData(pageIndexP + 1, Fileds);
    }
    const getQueryData = async (pageIndexP, Fileds) => {
        let params = {
            "UsePaging": false,
            "QueryId": "56557347-4bc2-4de2-9b0d-e65a5c2042b8",
            "parameters": {
                "_pageIndex": pageIndexP,
                "_pageSize": 20
            },
            "cluster": "biz"
        }
        params.parameters = { ...Fileds, ...params.parameters }
        setLoding(true)
        commonService.getQueryDataX(params).then((res) => {
            if (res && res.success) {
                if (pageIndexP == 1) {
                    setGalleryData(res.content.rows)
                } else {
                    let data = galleryData && JSON.parse(JSON.stringify(galleryData))
                    setGalleryData([...data, ...res.content.rows]);
                }
                setTotalCount(res.content.total)

            }
            setLoding(false)
        })
    }
    const controlType = (type) => {
        let retunType = "input";
        switch (type) {
            case "query":
            case "enum":
            case "select":
                retunType = "selectQ";
                break;
            case "queryRender":
                retunType = "selectRender";
                break;
            case "text":
                retunType = "input";
                break;
            case "number":
                retunType = "numberInput";
                break;
            case "date":
                retunType = "datePicker";
                break;
            case "date-range":
                retunType = "rangePicker";
                break;
            case "radio":
                retunType = "radio";
                break;
            case "upload":
                retunType = "upload";
                break;
            case "list":
                retunType = "list";
                break;
            case "richtext":
                retunType = "richtext";
                break;
            case "customQuery":
                retunType = "customQuery";
                break;
            case "cascader":
                retunType = "cascader";
                break;
            default:
                retunType = "input";
                break;
        }
        return retunType;
    };
    const GetQueryObjectX = async () => {
        let paramsArr = decodeURIComponent(window.location.search).substr(1).split("&");
        let paramsObj = {};
        paramsArr.forEach((item) => {
            let keyValueArr = item.split("=");
            if (paramsObj[keyValueArr[0]]) return;
            paramsObj[keyValueArr[0]] = keyValueArr[1];
        });
        console.log(paramsObj, "paramsObj");
        let params = {
            "QueryId": "56557347-4bc2-4de2-9b0d-e65a5c2042b8",
            "cluster": "biz"
        }
        setLoding(true)
        commonService.GetQueryObjectX(params).then((res) => {
            if (res && res.success) {
                let arr = [];
                let a = {};

                res.content.parameters.forEach(element => {
                    let obj = {
                        "lcol": 0,
                        "span": element.span || 6,
                        "label": "",
                        "cluster": element.cluster || 'biz',
                        "type": controlType(element.type),
                        "name": element.name || "name",
                        "defaultValue":  paramsObj[element.name] ? paramsObj[element.name] :(element.value ? element.value : undefined),
                        "cascadeName": element.cascadeName || null,
                        "queryId": element.queryId,
                        "placeholder": element.placeholder || element.title,
                    }
                    if (obj.type === "rangePicker") {
                        let indexP = paramsObj[element.name] && paramsObj[element.name].indexOf(",")
                        let varl = indexP ? {
                            start: paramsObj[element.name].slice(0, indexP),
                            end: (paramsObj[element.name].slice(indexP + 1))
                        } : null

                        obj.span = 6;
                        obj.formate = element.formate || "YYYY-MM-DD";
                        obj.defaultValue = varl ? [
                                moment(varl.start, element.formate),
                                moment(varl.end, element.formate),
                            ] :(element.value
                            ? [
                                moment(element.value.start, element.formate),
                                moment(element.value.end, element.formate),
                            ] :  undefined);
                        obj.placeholder = element.placeholder;

                    }
                    arr.push(obj)
                    a[obj.name] = obj.defaultValue;
                    console.log(obj, "=======")
                    form.setFieldsValue(a);
                });
                setParameters(arr);

            }
            setLoding(false)
        })
    }
    const onSearch = () => {
        form.validateFields().then((data) => {
            console.log(data);
            let Fileds = {};

            parameters && parameters.forEach((element) => {
                let datatype =
                    element.type == "rangePicker" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm";
                switch (element.type) {
                    case "rangePicker":
                        Fileds[element.name] = {
                            start:
                                data[element.name] && data[element.name][0]
                                    ? moment(data[element.name][0]).format(datatype)
                                    : "?",
                            end:
                                data[element.name] && data[element.name][1]
                                    ? moment(data[element.name][1]).format(datatype)
                                    : "?",
                        };
                        break;
                    case "selectQ":
                        Fileds[element.name] = data[element.name] ? data[element.name] : "?";
                        break;
                    default:
                        console.log(data[element.name]);
                        const target = data[element.name];
                        if (target || target === 0) {

                            Fileds[element.name] =
                                typeof target === "string"
                                    ? target.replace(/^\s*|\s*$/g, "")
                                    : target;
                        } else {
                            Fileds[element.name] = "?";
                        }
                        break;
                }
            })
            setFileds(Fileds)
            console.log("Fileds", Fileds);
            setGalleryData([])
            setPageIndex(1)
            getQueryData(1, Fileds)
        })
    }
    const onlinePreview = (data) => {
        let url = data.url + '&fullfilename=' + data.fileName;
        setIframeUrl('https://kkfileview-dev.smartxcloud.cn/onlinePreview?url=' + encodeURIComponent(Base64.encode(url)))
        setIsModalOpen(true)
        setLoding(true)
        // window.open('https://kkfileview-dev.smartxcloud.cn/onlinePreview?url='+encodeURIComponent(Base64.encode(url)));
    }
    const onCheckChange = (checkedValues) => {
        setCheckedList(checkedValues);
        setIndeterminate(!!checkedValues.length && checkedValues.length < galleryData.length);
        setCheckAll(checkedValues.length === galleryData.length);
        console.log('checked = ', checkedValues);
    };
    const onCheckAllChange = (e) => {
        console.log("e.target.checked", e.target.checked);
        setCheckedList(e.target.checked ? galleryData : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };
    return (<>
        <div className="smartGalleryBox">
            <Form
                form={form}
                name="advanced_search"
                className="gallery-search-form"
            >
                <Row gutter={8}>
                    {
                        parameters && <XFormItem
                            itemList={parameters}
                            callBack={() => { }}
                            formInfo={form}
                        ></XFormItem>
                    }

                    <Col
                        span={4}
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button type="primary" onClick={() => { onSearch() }} htmlType="submit">
                            <SearchOutlined />
                            查询
                        </Button>
                        {/* <Button
                            style={{
                                margin: '0 8px',
                            }}
                        >
                            打包下载
                        </Button> */}
                    </Col>
                </Row>
            </Form>
            <Tabs
                tabPosition='left'>
                <Tabs.TabPane tab="所有" key="1">

                    <div className="checkNum">
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                            全选
                        </Checkbox>
                        <span>已选择 {checkedList.length} 项</span></div>
                    <RViewerJS>
                        <ul className="scrollUl" onScroll={(scrollTop) => { pageIndex < 2 && scrollData(scrollTop) }} >

                            {galleryData && galleryData.length > 0 ?
                                <Checkbox.Group
                                    value={checkedList}
                                    onChange={onCheckChange}
                                >
                                    {galleryData.map((imgData) => (

                                        <li className="imgItem">
                                            <Checkbox value={imgData}></Checkbox>
                                            <Dropdown
                                                className="dropdownBtn"
                                                overlay={() => actionsMenu(imgData)}
                                                trigger={["click"]}
                                                placement="bottom"
                                            >
                                                <EllipsisOutlined />
                                            </Dropdown>
                                            {
                                                ['xlsx', 'pdf', 'docx'].includes(imgData.fileExtendType) ?
                                                    <a target="_blank" onClick={() => { onlinePreview(imgData) }}>
                                                        <XIconFont type={imgData.fileExtendType == 'xlsx' ? "icon-ECEL" : (imgData.fileExtendType == 'docx' ? "icon-WORD" : "icon-PDF")} className="item-icon" />
                                                    </a>
                                                    : <img
                                                        onError={(e) => {
                                                            e.target.src = 'https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/images/errorImg.png';
                                                        }}
                                                        className="imgP" src={imgData.url}></img>
                                            }

                                            <Tooltip title={imgData.fileName}> <p>{imgData.fileName}</p></Tooltip>
                                            <Tooltip title={imgData.catalog}> <p>{imgData.catalog}</p></Tooltip>
                                        </li>
                                        // </Checkbox>
                                    ))
                                    }
                                </Checkbox.Group> : <Empty />
                            }
                            {
                                loding && <div className="loadMore"><Spin tip="加载中..."></Spin></div>
                            }
                            {
                                pageIndex >= 2 && !loding &&
                                <div className="loadMore"><Button
                                    onClick={() => { lodeMore() }}
                                    style={{
                                        margin: '0 8px',
                                    }}
                                >
                                    <DownOutlined />加载更多
                                </Button></div>
                            }
                        </ul>


                    </RViewerJS>
                </Tabs.TabPane>
                <Tabs.TabPane tab="我的收藏" key="2">
                    敬请期待。。。
                </Tabs.TabPane>
            </Tabs>
            <Modal
                title="预览"
                width={1400}
                open={isModalOpen}
                onCancel={() => { setIsModalOpen(false) }}
                footer={[
                ]}>
                {
                    loding&&<div className="loadMore"><Spin tip="加载中...">
                        <Alert
                            message="请稍等"
                            description="文件加载中，请稍等"
                            type="info"
                        />
                    </Spin></div>
                }
                <iframe id="myIframe" onLoad={() => { setLoding(false) }} width='100%' height='800px' url={iframeUrl}></iframe>
            </Modal>
        </div>

    </>)
}
export default SmartGallery;