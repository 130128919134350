import React, { useRef,forwardRef,useImperativeHandle } from "react";
import Xform from "../../../components/Xform/xformRender";

const OriginalForm = forwardRef((props,ref) => {
  const { moduleItem } = props;
  useImperativeHandle(ref,()=>({
    hanndleSubmit,
  }))

  const hanndleSubmit = ()=>{
    console.log('到了这里来');
    
    XformRef.current.handleFlow()
  }
  const XformRef = useRef();

  return (
    <Xform
      ref={XformRef}
      XformData={{ formRef: moduleItem.formRef }}
      isPage={true}
      onSumbit={props.onSumbit}
    ></Xform>
  );
});

export default OriginalForm;
